<div class="wrapper">
  <nav id="sidebar" [class]="status ? 'active' : ''">
    <div class="sidebar-header">
      <div class="d-flex align-items-center">
        <img class="mb-0 seek img-fluid" src="Logo_QRserve.png" width="100" alt="">
        <!-- <p class="mb-0 seek">Hospitality Software</p> -->

        <div class="ms-auto">
          <button class="btn btn-clear" (click)="toggle()">
            <img class="mb-0 " src="icons/menu-tag.svg" alt="">
          </button>
        </div>
      </div>

    </div>
    <ul class="list-unstyled components">
      <li class="" (click)="collapseAll()">
        <a [routerLink]="['/admin/dashboard']" routerLinkActive="active"><img src="icons/dashboard.svg" alt="">&nbsp;&nbsp; <span class="seek">Dashboard</span></a>
      </li>
      <li class="" (click)="collapseAll()">
        <a [routerLink]="['/admin/user']" routerLinkActive="active"><img src="icons/user-management.svg" alt="">&nbsp;&nbsp; <span
            class="seek">User Management</span></a>
      </li>
      <li class="" (click)="collapseAll()">
        <a [routerLink]="['/admin/price_tags']" routerLinkActive="active"><img src="icons/activity-log.svg" alt="">&nbsp;&nbsp;
          <span class="seek">Price Tags</span></a>
      </li>
      <li class="position-relative">
        <a style="cursor: pointer;" (click)="collapse()" [class]="isCollapse? 'collapsed' : ''"
          class=" d-flex align-items-center">
          <img src="icons/inventory-management.svg" alt="">&nbsp;&nbsp; <span class="seek">Inventory Management</span>
          @if(!isCollapse){
          <i class="fa fa-solid fa-angle-right ms-auto"></i>
          } @else{
          <i class="fa fa-solid fa-angle-down ms-auto"></i>
          }
        </a>
        <ul class="collapse list-unstyled " id="homeSubmenu" [class]="isCollapse? 'show' : ''">
          <li>
            <a [routerLink]="['/admin/category']" routerLinkActive="active">Category</a>
          </li>
          <li>
            <a [routerLink]="['/admin/inventory']" routerLinkActive="active">Inventory</a>
          </li>
          <li>
            <a [routerLink]="['/admin/stock']" routerLinkActive="active">Stock</a>
          </li>
        </ul>
      </li>

      <li class="position-relative">
        <a style="cursor: pointer;" (click)="collapse2()" [class]="isCollapse2? 'collapsed' : ''"
          class=" d-flex align-items-center">
          <img src="icons/reporting.svg" alt="">&nbsp;&nbsp;
          <span class="seek">Reporting</span>
          @if(!isCollapse2){
          <i class="fa fa-solid fa-angle-right ms-auto"></i>
          } @else{
          <i class="fa fa-solid fa-angle-down ms-auto"></i>
          }
        </a>
        <ul class="collapse list-unstyled " id="homeSubmenu" [class]="isCollapse2? 'show' : ''">

          <li>
            <a [routerLink]="['/admin/sales_report']" routerLinkActive="active">Sales Report</a>
          </li>
          <li>
            <a [routerLink]="['/admin/payment_report']" routerLinkActive="active">Payment Report </a>
          </li>
          <li>
            <a [routerLink]="['/admin/financial_report']" routerLinkActive="active">Financial Report </a>
          </li>
          <li>
            <a [routerLink]="['/admin/inventory_report']" routerLinkActive="active">Inventory Report </a>
          </li>
        </ul>
      </li>
      <li class="" (click)="collapseAll()">
        <a [routerLink]="['/admin/activity_log']" routerLinkActive="active"><img src="icons/activity-log.svg" alt="">&nbsp;&nbsp;
          <span class="seek">Activity Log</span></a>
      </li>

      <!--
        <a style="cursor: pointer;" (click)="collapse3()" [class]="isCollapse3? 'collapsed' : ''"
          class=" d-flex align-items-center">
          <img src="icons/return-management.svg" alt="">&nbsp;&nbsp;
          <span class="seek">Return Management</span>
          @if(!isCollapse3){
          <i class="fa fa-solid fa-angle-right ms-auto"></i>
          } @else{
          <i class="fa fa-solid fa-angle-down ms-auto"></i>
          }
        </a>
        <ul class="collapse list-unstyled " id="homeSubmenu" [class]="isCollapse3? 'show' : ''">
          <li>
            <a [routerLink]="['/admin/return_requests']" routerLinkActive="active">Return Requests</a>
          </li>
          <li>
            <a [routerLink]="['/admin/return_history']" routerLinkActive="active">Return History</a>
          </li>

        </ul>
      </li> -->
      <!-- <li class="" (click)="collapseAll()">
        <a [routerLink]="['/admin/time_tracking']" routerLinkActive="active">
          <i class="fa fa-regular fa-stopwatch"></i>&nbsp;&nbsp;
          <span class="seek">Time Tracking</span></a>
      </li> -->
      <li class="" (click)="collapseAll()">
        <a [routerLink]="['/admin/barcode_management']" routerLinkActive="active">
          <img src="icons/barcode-management.svg" alt="">&nbsp;&nbsp;
          <span class="seek">Barcode Management</span></a>
      </li>
      <li class="" (click)="collapseAll()">
        <a [routerLink]="['/admin/system_configuration']" routerLinkActive="active">
          <img src="icons/system-configuration.svg" alt="">&nbsp;&nbsp;
          <span class="seek">System Configuration</span></a>
      </li>
      <!-- <li class="" (click)="collapseAll()">
        <a [routerLink]="['/admin/dispute_resolution']" routerLinkActive="active">
          <img src="icons/dispute-resolution.svg" alt="">&nbsp;&nbsp;
          <span class="seek">Dispute Resolution</span></a>
      </li> -->
      <li class="" (click)="collapseAll()">
        <a [routerLink]="['/admin/roles_and_permissions']" routerLinkActive="active">
          <img src="icons/roles.svg" alt="">&nbsp;&nbsp;
          <span class="seek">Roles and Permisions</span></a>
      </li>

    </ul>



  </nav>



  <div id="content">
    <div class="">
      <div class="row mt-3">
        <div class="col-4">
          <div class="d-flex align-items-center">
            @if (hasstrings) {
            <button class="btn btn-clear" (click)="back()">
              <i class="fa fa-solid fa-arrow-left fa-2xl me-3"></i>
            </button>
            }
            <h4 class="mb-0 page-title">{{ currentSegment |titlecase }}</h4>
          </div>

        </div>

        <div class="col-4"></div>
        <div class="col-4">
          <div class="d-flex align-items-center justify-content-end">
            <a [routerLink]="['/admin/notifications']" class="btn btn-clear">
              <img class="" src="icons/notification.svg" width="20" alt="">
            </a>
            <div ngbDropdown container="body">
              <button type="button" class="btn btn-clear btn-sm" ngbDropdownToggle>
                <img class="img-fluid" src="icons/waiter.svg" width="35" alt="">
              </button>
              <div ngbDropdownMenu>
                <button ngbDropdownItem>
                  <a [routerLink]="['/admin/profile']">Profile</a>
                </button>
                <button ngbDropdownItem (click)="logout()">Log Out</button>
              </div>
            </div>

            <!-- <div ngbDropdown class="d-inline-block">
              <div style="cursor: pointer;" id="dropdownBasic1" ngbDropdownToggle>
                <div class="img">
                  <img src="profile.png" class="img-fluid rounded-circle" alt="" srcset="">
                </div>
              </div>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <button [routerLink]="['/admin/profile']">Profile</button>
              </div>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <button ngbDropdownItem (click)="logout()">Log Out</button>

              </div>
            </div> -->
            <!-- <a href="">
              <div class="img">
                <img src="profile.png" class="img-fluid rounded-circle" alt="" srcset="">
              </div>
            </a> -->
          </div>
        </div>
      </div>
    </div>


    <router-outlet></router-outlet>
  </div>
</div>
