import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { InventoryComponent } from './inventory/inventory.component';
import { CategoryComponent } from './category/category.component';
import { UserMgtComponent } from './user-mgt/user-mgt.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './auth/login/login.component';
import { authGuard } from './_guard/auth.guard';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { CreateUserComponent } from './user-mgt/create-user/create-user.component';
import { CreateInventoryComponent } from './inventory/create-inventory/create-inventory.component';
import { StockComponent } from './stock/stock.component';
import { AuditoryLogsComponent } from './auditory-logs/auditory-logs.component';
import { SalesComponent } from './sales/sales.component';
import { OperationalReportComponent } from './waiter-performance/waiter-performance.component';
import { InventoryReportComponent } from './inventory-report/inventory-report.component';
import { FinancialReportComponent } from './financial-report/financial-report.component';
import { AuditLogComponent } from './activity-logs/audit-log.component';
import { ReturnRequestsComponent } from './returnMGT/return-requests/return-requests.component';
import { ReturnHistoryComponent } from './returnMGT/return-history/return-history.component';
import { TimeTrackingComponent } from './time-tracking/time-tracking.component';
import { BarcodeMgtComponent } from './barcode-mgt/barcode-mgt.component';
import { CustomerMgtComponent } from './customer-mgt/customer-mgt.component';
import { OrderHistoryComponent } from './order-history/order-history.component';
import { SystemConfigurationComponent } from './system-configuration/system-configuration.component';
import { DisputeResolutionComponent } from './dispute-resolution/dispute-resolution.component';
import { OrderDetailsComponent } from './order-details/order-details.component';
import { RolesComponent } from './roles/roles.component';
import { CreateRolesComponent } from './roles/create-roles/create-roles.component';
import { ProfileComponent } from './profile/profile.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { ViewNotificationComponent } from './notifications/view-notification/view-notification.component';
import { WaiterManagementComponent } from './waiter-management/waiter-management.component';
import { WaiterDetailsComponent } from './waiter-management/waiter-details/waiter-details.component';
import { PriceTagsComponent } from './price-tags/price-tags.component';
import { InventoryAuditoryLogsComponent } from './inventory/inventory-auditory-logs/inventory-auditory-logs.component';
import { PaymentReportComponent } from './payment-report/payment-report.component';
import { BulkUploadComponent } from './bulk-upload/bulk-upload.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'admin',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'reset_password',
    component: ResetPasswordComponent
  },
  {
    path: 'admin',
    component: HomeComponent,
    canActivate: [authGuard],
    children: [
      {
        path: '',
        redirectTo: 'user',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        component: DashboardComponent
      },
      {
        path: 'user',
        component: UserMgtComponent
      },
      {
        path: 'user/create_new_user',
        component: CreateUserComponent
      },
      {
        path: 'bulk_upload',
        component: BulkUploadComponent
      },
      {
        path: 'user/edit_user/:id',
        component: CreateUserComponent
      },
      {
        path: 'price_tags',
        component: PriceTagsComponent
      },
      {
        path: 'category',
        component: CategoryComponent
      },
      {
        path: 'inventory',
        component: InventoryComponent
      },
      {
        path:'inventory/inventory_stock/:id',
        component: InventoryAuditoryLogsComponent
      },
      {
        path: 'inventory/create_inventory',
        component: CreateInventoryComponent
      },
      {
        path: 'inventory/edit_inventory/:id',
        component: CreateInventoryComponent
      },
      {
        path: 'stock',
        component: StockComponent
      },
      {
        path: 'sales_report',
        component: SalesComponent
      },
      {
        path: 'operational_report',
        component: OperationalReportComponent
      },
      {
        path: 'payment_report',
        component: PaymentReportComponent
      },
      {
        path: 'inventory_report',
        component: InventoryReportComponent
      },
      {
        path: 'financial_report',
        component: FinancialReportComponent
      },
      {
        path: 'activity_log',
        component: AuditLogComponent
      },
      {
        path: 'return_requests',
        component: ReturnRequestsComponent
      },
      {
        path: 'return_history',
        component: ReturnHistoryComponent
      },
      {
        path: 'time_tracking',
        component: TimeTrackingComponent
      },
      {
        path: 'barcode_management',
        component: BarcodeMgtComponent
      },
      {
        path: 'customer_management',
        component: CustomerMgtComponent
      },
      {
        path: 'customer_management/order_history/:id',
        component: OrderHistoryComponent
      },
      {
        path: 'system_configuration',
        component: SystemConfigurationComponent
      },
      {
        path: 'dispute_resolution',
        component: DisputeResolutionComponent
      },
      {
        path: 'dispute_resolution/order_details/:id',
        component: OrderDetailsComponent
      },
      {
        path: 'roles_and_permissions',
        component: RolesComponent
      },
      {
        path: 'roles_and_permissions/create_role',
        component: CreateRolesComponent
      },
      {
        path: 'profile',
        component: ProfileComponent
      },
      {
        path: 'notifications',
        component: NotificationsComponent
      },
      {
        path: 'notification/:id',
        component: ViewNotificationComponent
      },
      {
        path: 'waiter',
        component: WaiterManagementComponent
      },
      {
        path: 'waiter/waiter_details/:id',
        component: WaiterDetailsComponent
      },
    ]
  },
];
