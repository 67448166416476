import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReportingService } from '../_service/reporting.service';
import Swal from 'sweetalert2';

interface Transaction {
  id: number;
  amount: number;
  bank: string;
  date: Date;
  status: string;
}

interface Bank {
  id: number;
  name: string;
}

@Component({
  selector: 'app-wallet',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './wallet.component.html',
  styleUrl: './wallet.component.scss'
})
export class WalletComponent {
  private reportingSrvs = inject(ReportingService);
  balance: any; // Example balance
  unAppBalance: any; // Example balance
  showWithdrawModal: boolean = false;
  selectedBank: string = '';
  withdrawAmount: number = 0;
  
  banks: any;

  transactions: Transaction[] = [
    { id: 1, amount: 1000, bank: 'Bank A', date: new Date(), status: 'Completed' },
    { id: 2, amount: 500, bank: 'Bank B', date: new Date(), status: 'Pending' }
  ];
  isWalletBalanceHidden: boolean = false;
  isUnappliedBalanceHidden: boolean = false;

  ngOnInit(): void {
    // Load balance visibility preferences from localStorage
    this.isWalletBalanceHidden = localStorage.getItem('hideWalletBalance') === 'true';
    this.isUnappliedBalanceHidden = localStorage.getItem('hideUnappliedBalance') === 'true';
    this.getWalletBalance();
    this.getUnAppWalletBalance();
    this.fetchBanks();
  }

  toggleWalletBalanceVisibility() {
    this.isWalletBalanceHidden = !this.isWalletBalanceHidden;
    localStorage.setItem('hideWalletBalance', this.isWalletBalanceHidden.toString());
  }

  toggleUnappliedBalanceVisibility() {
    this.isUnappliedBalanceHidden = !this.isUnappliedBalanceHidden;
    localStorage.setItem('hideUnappliedBalance', this.isUnappliedBalanceHidden.toString());
  }
  openWithdrawModal() {
    this.showWithdrawModal = true;
  }
  getWalletBalance() {
    this.reportingSrvs.getWalletBalance().subscribe({
      next: (res: any) => {
        this.balance = res;
      },
      error: (error) => {
        console.error('Error fetching watlle balance:', error);
      }
    });
  }
  getUnAppWalletBalance() {
    this.reportingSrvs.getUnAppWalletBalance().subscribe({
      next: (res: any) => {
        this.unAppBalance = res;
      },
      error: (error) => {
        console.error('Error fetching watlle balance:', error);
      }
    });
  }
  fetchBanks() {
    this.reportingSrvs.getBanks().subscribe({
      next: (res: any) => {
        this.banks = res;
      },
      error: (error) => {
        console.error('Error fetching watlle balance:', error);
      }
    });
  }
  closeWithdrawModal() {
    this.showWithdrawModal = false;
    this.selectedBank = '';
    this.withdrawAmount = 0;
  }

  submitWithdrawal() {
    if (this.withdrawAmount > 0 && this.selectedBank) {
      // Add withdrawal logic here
      let payload = {
        amount: this.withdrawAmount,
        bank_id: this.selectedBank
      }
      this.reportingSrvs.submitWithdrawal(payload).subscribe({
        next: (res: any) => {
          this.closeWithdrawModal();
          Swal.fire({
            title: 'Success!',
            text: 'Withdrawal submitted successfully.',
            icon: 'success',
            confirmButtonText: 'OK'
          });
        },
        error: (error) => {
          console.error('Error submitting:', error);
          Swal.fire({
            title: 'Error!',
            text: error.response.error.message,
            icon: 'error',
            confirmButtonText: 'OK'
          });
        }
      });
    }
  }
}
