import { finalize } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { PaginatedResponse, SalesHistory, SalesHistoryParams, WaiterPerformance } from '../sales/sales.component';
import { Observable } from 'rxjs';
import { PaymentReportHistory } from '../financial-report/financial-report.component';
@Injectable({
  providedIn: 'root'
})
export class ReportingService {
  http = inject(HttpClient);
  auth = inject(AuthService)
  baseUrl = environment.baseApi
  private authLocalStorageToken = `user`;
  user = this.auth.getUser(this.authLocalStorageToken)
  loadingBar = inject(LoadingBarService)


  salesSummary() {
    this.loadingBar.useRef().start()
    return this.http.get(`${this.baseUrl}/dashboard/stats`).
      pipe(
        finalize(() => { this.loadingBar.stop(); })
      )
  }
  totalSummary() {
    this.loadingBar.useRef().start()
    return this.http.get(`${this.baseUrl}/sales/summary`).
      pipe(
        finalize(() => { this.loadingBar.stop(); })
      )
  }
  salesChart() {
    this.loadingBar.useRef().start()
    return this.http.get(`${this.baseUrl}/dashboard/charts`).
      pipe(
        finalize(() => { this.loadingBar.stop(); })
      )
  }

  getTopSummary() {
    this.loadingBar.useRef().start()
    return this.http.get(`${this.baseUrl}/admin-sales-reports/top-summary`).
      pipe(
        finalize(() => { this.loadingBar.stop(); })
      )
  }
  getSalesSummary() {
    this.loadingBar.useRef().start()
    return this.http.get(`${this.baseUrl}/admin-sales-reports/summary`).
      pipe(
        finalize(() => { this.loadingBar.stop(); })
      )
  }
  getPaymentSummary() {
    this.loadingBar.useRef().start()
    return this.http.get(`${this.baseUrl}/payment-reports/summary`).
      pipe(
        finalize(() => { this.loadingBar.stop(); })
      )
  }
  getWalletBalance() {
    this.loadingBar.useRef().start()
    return this.http.get(`${this.baseUrl}/tenant/balance`).
      pipe(
        finalize(() => { this.loadingBar.stop(); })
      )
  }
  getUnAppWalletBalance() {
    this.loadingBar.useRef().start()
    return this.http.get(`${this.baseUrl}/system/balance`).
      pipe(
        finalize(() => { this.loadingBar.stop(); })
      )
  }
  getBanks() {
    this.loadingBar.useRef().start()
    return this.http.get(`${this.baseUrl}/bank-accounts`).
      pipe(
        finalize(() => { this.loadingBar.stop(); })
      )
  }
  getPaymentSummarys(params: any = {}) {
    this.loadingBar.useRef().start()
    return this.http.get(`${this.baseUrl}/operational-reports/reports/payment-summary`, { params }).
      pipe(
        finalize(() => { this.loadingBar.stop(); })
      )
  }
  getWaiterPaymentReport(params: any = {}) {
    this.loadingBar.useRef().start()
    return this.http.get(`${this.baseUrl}/operational-reports/reports/waiter-payment-report`, { params }).
      pipe(
        finalize(() => { this.loadingBar.stop(); })
      )
  }
  getWaiterSalesReport(dateParams: { start_date: string, end_date?: string }) {
    let params = new HttpParams()
    .set('start_date', dateParams.start_date);

  if (dateParams.end_date) {
    params = params.set('end_date', dateParams.end_date);
  }
    this.loadingBar.useRef().start()
    return this.http.get(`${this.baseUrl}/sales-reports/waiter-sales`, { params }).
      pipe(
        finalize(() => { this.loadingBar.stop(); })
      )
  }
  getWaiterPerformance(page: number = 1): Observable<PaginatedResponse<WaiterPerformance>> {
    return this.http.post<PaginatedResponse<WaiterPerformance>>(`${this.baseUrl}/operational-reports/waiter-performance-report?page=${page}`, {});
  }
  getPaymentHistory(page: number = 1): Observable<PaginatedResponse<PaymentReportHistory>> {
    return this.http.post<PaginatedResponse<PaymentReportHistory>>(`${this.baseUrl}/payment-reports/reports?page=${page}`, {});
  }
  getSalesHistory(params: SalesHistoryParams): Observable<PaginatedResponse<SalesHistory>> {
    let httpParams = new HttpParams();

    // Explicitly handle each possible parameter
    if (params.page !== undefined) {
      httpParams = httpParams.set('page', params.page.toString());
    }
    if (params.start_date) {
      httpParams = httpParams.set('start_date', params.start_date);
    }
    if (params.end_date) {
      httpParams = httpParams.set('end_date', params.end_date);
    }
    if (params.payment_method) {
      httpParams = httpParams.set('payment_method', params.payment_method);
    }
    if (params.waiter_id) {
      httpParams = httpParams.set('waiter_id', params.waiter_id);
    }

    const url = `${this.baseUrl}/payment-reports/reports`;

    return this.http.post<PaginatedResponse<SalesHistory>>(url, {}, {
      params: httpParams
    });
  }
  salesHistory() {
    this.loadingBar.useRef().start()
    return this.http.get(`${this.baseUrl}/sales/history`).pipe(
      finalize(() => { this.loadingBar.stop(); })
    )
  }
  fulfilmentSummary() {
    this.loadingBar.useRef().start()
    return this.http.get(`${this.baseUrl}/waiter-performances/fulfillment-summary`)
      .pipe(finalize(() => { this.loadingBar.stop(); }))
  }
  getOrderDelaySummary() {
    this.loadingBar.useRef().start()
    return this.http.post(`${this.baseUrl}/operational-reports/order-delay-summary`, {})
      .pipe(finalize(() => { this.loadingBar.stop(); }))
  }
  waiterPerformanceSummary() {
    this.loadingBar.useRef().start()
    return this.http.post(`${this.baseUrl}/operational-reports/waiter-performance-summary`, {})
      .pipe(finalize(() => { this.loadingBar.stop(); }))
  }
  feedbackReport() {
    this.loadingBar.useRef().start()
    return this.http.get(`${this.baseUrl}/waiter-performances/feedback-report`)
      .pipe(finalize(() => { this.loadingBar.stop(); }))
  }
  buzzWaiterReport() {
    this.loadingBar.useRef().start()
    return this.http.get(`${this.baseUrl}/waiter-performances/buzzwaiter-report`)
      .pipe(finalize(() => { this.loadingBar.stop(); }))
  }

  inventorySummaryReport() {
    this.loadingBar.useRef().start()
    return this.http.get(`${this.baseUrl}/inventory-reports/detailed-statistics`)
      .pipe(finalize(() => { this.loadingBar.stop(); }))
  }
  inventoryExpirationDate() {
    this.loadingBar.useRef().start()
    return this.http.get(`${this.baseUrl}/inventory-reports/expiration-date`)
      .pipe(finalize(() => { this.loadingBar.stop(); }))
  }
  inventoryLowStockAlertReport() {
    this.loadingBar.useRef().start()
    return this.http.get(`${this.baseUrl}/inventory-reports/low-stock-alert`)
      .pipe(finalize(() => { this.loadingBar.stop(); }))
  }

  inventoryTurnoverReport() {
    this.loadingBar.useRef().start()
    return this.http.get(`${this.baseUrl}/inventory-reports/inventory-turnover`)
      .pipe(finalize(() => { this.loadingBar.stop(); }))
  }
  submitWithdrawal(payload: any) {
    this.loadingBar.useRef().start()
    return this.http.post(`${this.baseUrl}/withdraw`, payload)
      .pipe(finalize(() => { this.loadingBar.stop(); }))
  }

}
