<div class="container mt-5">
    <h2 class="mb-4">Bulk Upload Stocks</h2>
  
    <!-- Search Input -->
    <div class="mb-3">
      <label for="search" class="form-label">Search Inventory</label>
      <input
        type="text"
        id="search"
        class="form-control"
        [(ngModel)]="searchTerm"
        placeholder="Search by name"
        (input)="filterInventories()"
      />
    </div>
  
    <!-- Inventory Table -->
    <div class="table-responsive">
      <table class="table table-bordered table-hover">
        <thead class="thead-light">
          <tr>
            <th>Item Name</th>
            <th>Quantity</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let inventory of filteredInventories">
            <td>{{ inventory.name }}</td>
            <td>
              <input
                type="number"
                class="form-control"
                [(ngModel)]="inventory.quantity"
                [attr.min]="1"
                placeholder="Enter quantity"
              />
            </td>
          </tr>
          <tr *ngIf="filteredInventories.length === 0">
            <td colspan="2" class="text-center">No items found.</td>
          </tr>
        </tbody>
      </table>
    </div>
  
    <!-- Bulk Upload Button -->
    <div class="mt-4">
      <button
        class="btn btn-primary btn-lg px-5"
        (click)="bulkUpload()"
        [disabled]="isLoading || filteredInventories.length === 0"
      >
        <span *ngIf="isLoading" class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
        {{ isLoading ? 'Uploading...' : 'Upload All' }}
      </button>
    </div>
  </div>
  