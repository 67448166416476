<div class="row mt-5">
  <div class="col-12">
    <div class="table-responsive">
      <table class="table align-middle border rounded">
        <thead>
          <tr>
            <th scope="col" class="py-3 ps-3"></th>
            <th scope="col" class="py-3">Name</th>
            <th scope="col" class="py-3">Category</th>
            <th scope="col" class="py-3">Quantity</th>
            <th scope="col" class="py-3">Expiry Date</th>
            <th scope="col" class="py-3">Cost Price</th>
            <th scope="col" class="py-3">Selling Price</th>
            <th scope="col" class="py-3">Total Price</th>
            <th scope="col" class="py-3">Expired</th>
            <th scope="col" class="py-3">Status</th>
            <th scope="col" class="py-3">Action</th>
          </tr>
        </thead>
        <tbody>
          @for(log of logs; track log.id) {
            <tr>
              <td scope="row" class="ps-3" style="width: 60px;">
                <img [src]="log.inventory.main_image || 'profile.png'" width="50" height="50" class="rounded" alt="">
              </td>
              <td>{{log?.inventory?.name}}</td>
              <td>{{log?.inventory?.category?.name}}</td>
              <td>{{log?.quantity}}</td>
              <td>{{log?.expiry_date | date}}</td>
              <td>{{log?.cost_price | currency:'₦'}}</td>
              <td>{{log?.inventory?.selling_price | currency:'₦'}}</td>
              <td>{{log?.total_cost | currency:'₦'}}</td>
              <td>
                <span>{{log.is_expired ? 'Yes' : 'No'}}</span>
              </td>
              <td>
                <span [class]="log.sold_out ? 'badge text-secondary text-bg-secondary py-2 px-4 rounded rounded-pill' : ''">
                  {{log.sold_out ? 'Sold out' : 'Available'}}
                </span>
              </td>
              <td>
                <div ngbDropdown container="body" display="dynamic" class="d-inline-block">
                  <div class="cursor-pointer" id="dropdownBasic1" ngbDropdownToggle>
                   <i class="bi bi-three-dots"></i>
                  </div>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <button ngbDropdownItem class="p-3" (click)="toggleStatus(log)">
                      <i class="fa fa-regular" [class.fa-check-circle]="log.sold_out" [class.fa-ban]="!log.sold_out"></i>
                      <span class="ms-2">Switch to {{log.sold_out ? 'Available' : 'Sold Out'}}</span>
                    </button>
                    <button ngbDropdownItem class="p-3" (click)="openUpdateModal(updateQuantityModal, log)">
                      <i class="fa fa-light fa-pen me-2"></i>
                      Update Quantity
                    </button>
                    <button ngbDropdownItem class="p-3" (click)="view(log.receipt)">
                    <i class="fa fa-regular fa-eye me-3"></i>
                    View Receipt</button>
                  </div>
                </div>
              </td>
            </tr>
          } @empty {
            <tr class="text-center">
              <td colspan="11">
                <div class="my-5 text-center">
                  <img src="cook.png" alt="">
                  <p class="mt-3">There are no logs at the moment</p>
                </div>
              </td>
            </tr>
          }
        </tbody>
      </table>
      @if (size > pageSize) {
        <ngb-pagination
          class="d-flex justify-content-end"
          [collectionSize]="size"
          [(page)]="page"
          [pageSize]="pageSize"
          [maxSize]="5"
          [rotate]="true"
          [ellipses]="false"
          [boundaryLinks]="true"
          (pageChange)="refresh($event)" />
      }
    </div>
  </div>
</div>

<!-- Update Quantity Modal -->
<ng-template #updateQuantityModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Update Quantity</h4>
    <button type="button" class="btn-close" (click)="modal.dismiss()"></button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label for="quantity">New Quantity</label>
      <input
        type="number"
        class="form-control"
        id="quantity"
        [(ngModel)]="updatingQuantity"
        min="0">
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.dismiss()">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="modal.close('save')">Save Changes</button>
  </div>
</ng-template>
