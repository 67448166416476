<div class="row align-items-center mt-5">
  <div class="col-md-4">
    <div class="search-box">
      <input type="text" class="search-input" placeholder="Search" />
    </div>
  </div>
  <div class="col-md-8 text-md-end">
    <button class="btn btn-lg btn-primary spec-btn" (click)="generateBarcode(content)">Create Tag</button>
  </div>
</div>

<div class="mt-3">
  <div class="table-responsive">
    <table class="table align-middle border rounded">
      <thead>
        <tr>
          <th class="py-3">Tag Name</th>
          <th class="py-3">Status</th>
          <th class="py-3">Action</th>
        </tr>
      </thead>
      <tbody>
        @for (item of priceTagList; track item.id) {
        <tr>
          <td>{{ item.name }}</td>
          <td>{{ item.status }}</td>
          <td>
            <div ngbDropdown class="d-inline-block" container="body">
              <div class="cursor-pointer" id="dropdownBasic1" ngbDropdownToggle>
               <i class="bi bi-three-dots"></i>
              </div>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <button ngbDropdownItem (click)="editTag(item, content)">
                  <i class="fa fa-regular fa-pen-to-square"></i> &nbsp; Edit Price Tag
                </button>
                <button ngbDropdownItem (click)="delete(item.id)">
                  <i class="fa fa-regular fa-ban"></i> &nbsp; Delete Price Tag
                </button>
              </div>
            </div>
          </td>
        </tr>
        }
      </tbody>
    </table>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-body p-4">
    <div class="d-flex">
      <button class="btn btn-clear" (click)="modal.close('close-click')">
        <i class="fa fa-regular fa-arrow-left fa-2xl"></i>
      </button>
      <div class="ms-auto me-auto">
        <h3 class="mb-0">{{ isEditing ? 'Edit' : 'Create' }} Price Tag</h3>
      </div>
    </div>
    <div class="form-area">
      <form [formGroup]="generateForm" (ngSubmit)="onsubmit()" class="mt-4">
        <div class="form-group">
          <label for="" class="form-label">Tag Name</label>
          <input type="text" class="form-control" name="name" formControlName="name">
        </div>
        <div class="mt-4 text-center">
          <button class="btn btn-primary btn-half_block btn-lg spec-btn" type="submit" [disabled]="isLoading">
            <span *ngIf="isLoading" class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
            {{ isLoading ? 'Creating Tag...' : (isEditing ? 'Update Tag' : 'Create Tag') }}
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-template>
