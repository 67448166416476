<!-- sales.component.html -->
<div class="mt-4">
  <!-- Sales Summary Section -->
  <div class="row mt-4">
    <div class="col-md-4 mb-3">
      <div class="balance-card py-3 px-4">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <p class="balance-text mb-2">Wallet Balance</p>
            <h3 class="balance-amount mb-0">
              ₦ {{walletBalance?.formatted_balance	}}
            </h3>
           
          </div>

        </div>
      </div>
    </div>
    <div class="col-md-4 mb-3" *ngFor="let summary of summaryCards">
      <div class="balance-card py-3 px-4">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <p class="balance-text mb-2">{{summary.title}}</p>
            <h3 class="balance-amount mb-0" *ngIf="summary.title !== 'Total Sales'; else totalSales">
              ₦{{summary.value | number}}
            </h3>
            <ng-template #totalSales>
              <h3 class="balance-amount mb-0">{{summary.value | number}}</h3>
            </ng-template>
          </div>

        </div>
      </div>
    </div>
  </div>
  <!-- Employee Sales Table -->
  <div class="card mt-4">
    <div class="card-header bg-white">
      <h6 class="mb-0">Payment History</h6>
    </div>
    <div class="card-body">
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th>Order ID</th>
              <th>Order Amount (₦)</th>
              <th>Payment Method</th>
              <th>Status</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let history of paymentHistory">
              <td>
                {{ history.order_uid }}
              </td>
              <td>{{history.order_amount | currency:'₦'}}</td>
              <td> <span *ngFor="let method of history.payment_method" class="badge bg-light text-dark me-1">
                {{method | titlecase}}
              </span></td>
              <td>
                @if (history.status === 'completed') {
                  <span class="badge badge-success">Paid</span>
                } @else if (history.status === 'closed') {
                  <span class="badge badge-danger">Closed</span>
                } @else {
                  <span class="badge badge-warning">Unpaid</span>
                }
              </td>
              <td>{{history.transaction_date | date:'medium'}}</td>
            </tr>
            @if (paymentHistory.length === 0) {
            <tr>
              <td colspan="5" class="text-center py-4">
                No Payment history data available
              </td>
            </tr>
            }
          </tbody>
        </table>
      </div>
      @if (size > pageSize) {
      <div class="mt-3">
        <ngb-pagination class="d-flex justify-content-end" [collectionSize]="size" [(page)]="page"
          [pageSize]="pageSize" [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"
          (pageChange)="refresh($event)">
        </ngb-pagination>
      </div>
      }
    </div>
  </div>
</div>

