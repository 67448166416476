<div class="wallet-container">
 <div class="row">
  <div class="col-md-6 mb-3">
    <div class="balance-section">
      <h2>Wallet Balance</h2>
      <div class="balance-wrapper">
        <div class="balance">
          <ng-container *ngIf="!isWalletBalanceHidden; else hiddenWalletBalance">
            ₦{{ balance?.formatted_balance }}
          </ng-container>
          <ng-template #hiddenWalletBalance>
            <span>****</span>
          </ng-template>
        </div>
        <button class="toggle-visibility" (click)="toggleWalletBalanceVisibility()">
          <i class="bi" [ngClass]="isWalletBalanceHidden ? 'bi bi-eye' : 'bi bi-eye-slash'"></i>
        </button>
      </div>
      <button class="withdraw-btn" (click)="openWithdrawModal()">Withdraw</button>
    </div>
  </div>
  <div class="col-md-6 mb-3">
    <div class="balance-section">
      <h2>Unapplied Balance</h2>
      <div class="balance-wrapper">
        <div class="balance">
          <ng-container *ngIf="!isUnappliedBalanceHidden; else hiddenUnappliedBalance">
            ₦{{ unAppBalance?.formatted_balance }}
          </ng-container>
          <ng-template #hiddenUnappliedBalance>
            <span>****</span>
          </ng-template>
        </div>
        <button class="toggle-visibility" (click)="toggleUnappliedBalanceVisibility()">
          <i class="fas" [ngClass]="isUnappliedBalanceHidden ? 'bi bi-eye' : 'bi bi-eye-slash'"></i>
        </button>
      </div>
    </div>
  </div>
 </div>

  <!-- <div class="transactions-section">
    <h3>Withdrawal History</h3>
    <table>
      <thead>
        <tr>
          <th>Date</th>
          <th>Amount</th>
          <th>Bank</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let transaction of transactions">
          <td>{{ transaction.date | date:'medium' }}</td>
          <td>£{{ transaction.amount | number:'1.2-2' }}</td>
          <td>{{ transaction.bank }}</td>
          <td>{{ transaction.status }}</td>
        </tr>
      </tbody>
    </table>
  </div> -->

  <!-- Withdraw Modal -->
  <div class="modal" *ngIf="showWithdrawModal">
    <div class="modal-content">
      <h3>Withdraw Funds</h3>
      <div class="form-group">
        <label for="bank">Select Bank</label>
        <select id="bank" [(ngModel)]="selectedBank">
          <option value="">Select a bank</option>
          <option *ngFor="let bank of banks" [value]="bank.id">
            {{bank.account_number + ' ' + bank.bank + ' ' + bank.account_name }}
          </option>
        </select>
      </div>
      <div class="form-group">
        <label for="amount">Amount</label>
        <input type="number" id="amount" [(ngModel)]="withdrawAmount" min="0" [max]="balance">
      </div>
      <div class="modal-actions">
        <button (click)="closeWithdrawModal()">Cancel</button>
        <button (click)="submitWithdrawal()" [disabled]="!selectedBank || withdrawAmount <= 0">
          Confirm Withdrawal
        </button>
      </div>
    </div>
  </div>
</div>