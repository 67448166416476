import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, inject, OnInit, signal } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReportingService } from '../_service/reporting.service';
import { InventoryService } from '../_service/inventory.service';
import { NgxEchartsDirective } from 'ngx-echarts';
import type { EChartsOption } from 'echarts';
import { getInstanceByDom, connect } from 'echarts';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [NgbModule, RouterModule, CommonModule, NgxEchartsDirective],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent implements OnInit, AfterViewInit {

  totalSales: any = 1000;
  reportingSrvs = inject(ReportingService)
  modal = inject(NgbModal)
  invetorySrvs = inject(InventoryService)
  router = inject(Router)
  inventories: any = signal('');
  salesHistory: any;
  inventoryService = inject(InventoryService)
  activityLogs: any;
  cartValue: number = 0; // New property to store cart value

  page = 1
  size = 0
  pageSize = 1
  page2 = 10
  categories: any;
  selectedOrder: any = null;
  options: any = {};

  salesChart: any;
  totalItems: any;
  currentPage: any;
  pendingOrders: any;
  activeWaiters: any;

  ngAfterViewInit(): void {
    // setTimeout(() => {
    //   const chartElement1: any = document.getElementById('chart1');
    //   const chartElement2: any = document.getElementById('chart2');
    //   const chart1: any = getInstanceByDom(chartElement1);
    //   const chart2: any = getInstanceByDom(chartElement2);
    //   connect([chart1, chart2]);
    // });
  }

  ngOnInit(): void {
    this.getSalesSummary()
    this.fetchPendingOrders();
    this.fetchActiveWaiters();
    this.getSalesChart()
    this.listInventory()
    this.viewActivityLogs()
  }
  getTotalOrderAmount(): number {
    if (!this.selectedOrder || !this.selectedOrder.order_items) {
      return 0;
    }
    return this.selectedOrder.order_items.reduce((total: any, item: any) => total + (parseFloat(item.amount) * item.quantity), 0);
  }
  public closeModal(): void {
    this.modal.dismissAll();
  }
  openOrderDetails(order: any, content:any) {
    this.selectedOrder = order;
    const modalRef = this.modal.open(content, { size: 'lg' });
  }
  getSalesSummary() {
    this.reportingSrvs.salesSummary().subscribe((data) => {
      this.totalSales = data;
      console.log('totalSales', this.totalSales)
    })
  }
  fetchPendingOrders() {
    this.inventoryService.fetchPendingOrders().subscribe((res: any) => {
      this.pendingOrders = res.data;
      console.log('Pending Orders', this.pendingOrders)
    })
  }
  fetchActiveWaiters() {
    this.inventoryService.fetchActiveWaiters().subscribe((res: any) => {
      this.activeWaiters = res.data;
      console.log('Active Waiters', this.activeWaiters)
    })
  }
  viewActivityLogs(page: number = 1) {
    this.inventoryService.activityLogs(page).subscribe({
      next: (response: any) => {
        this.activityLogs = response.data;
        this.totalItems = response.meta.total;
        this.currentPage = response.meta.current_page;
        this.pageSize = response.meta.per_page;
      },
      error: (err) => {
        console.error('Error fetching activity logs:', err);
      },
      complete: () => {
      }
    });
  }
  getSalesChart() {
    this.reportingSrvs.salesChart().subscribe({
      next: (res: any) => {
        this.salesChart = res
        this.options = {
          color: ['#00a859'],
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
            },
          },
          grid: {
            left: '0%',
            right: '0%',
            bottom: '3%',
            containLabel: true,
          },
          xAxis: [
            {
              type: 'category',
              data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
              axisTick: {
                alignWithLabel: true,
              },
            },
          ],
          yAxis: [
            {
              type: 'value',
            },
          ],
          series: [
            {
              name: 'Orders',
              type: 'bar',
              barWidth: '60%',
              data: this.convertObjectToArray(res.monthly_orders),
            },
          ],
        };
      }, error: (err) => {
        console.log(err)
      }

    })
  }
  convertObjectToArray(data: any[]) {
    // Assuming data is an array of objects
    return Object.values(data);
  }
  getCurrentYear(): number {
    const currentYear = new Date().getFullYear();
    return currentYear;
  }
  listInventory() {
    this.invetorySrvs.listInventories().subscribe({
      next: (res: any) => {
        console.log(res)
        this.inventories.set(res.data)
        const meta = res.meta
        this.size = meta.total
        this.pageSize = meta.per_page
      }
    })
  }

  addInv() {
    this.router.navigateByUrl('/admin/inventory')
  }

  updateCartValue() {
    // Logic to update the cart value based on this.salesChart
    if (this.salesChart && Array.isArray(this.salesChart)) {
      // Assuming this.salesChart.data contains the relevant cart information
      this.cartValue = this.salesChart.reduce((acc: number, value: number) => acc + value, 0);
      console.log('Updated cart value:', this.cartValue);
    }
  }
  closeOrder(orderId: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to close this order?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, close it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.inventoryService.closeOrder(orderId).subscribe({
          next: (response) => {
            Swal.fire(
              'Closed!',
              'Order has been closed.',
              'success'
            );
            this.closeModal();
            this.fetchPendingOrders(); // Refresh the pending orders list
          },
          error: (error) => {
            Swal.fire(
              'Error!',
              'Failed to close the order.',
              'error'
            );
          }
        });
      }
    });
  }
}
