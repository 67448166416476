import { Component, inject, OnInit } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../_service/user.service';
import { AuthService } from '../_service/auth.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-user-mgt',
  standalone: true,
  imports: [NgbModule, FormsModule],
  templateUrl: './user-mgt.component.html',
  styleUrl: './user-mgt.component.scss'
})
export class UserMgtComponent implements OnInit {
  userService = inject(UserService)
  auth = inject(AuthService)
  router = inject(Router)
  userDetails!: any;
  users: any;
  page = 1
  size = 0
  pageSize = 1
  page2 = 10
  search: any = ''
  filter: any = ''
  status: any = '';
  selectedRole: string = '';
  selectedStatus: string = '';
  // test = [1, 2, 3, 4, 5]

  ngOnInit(): void {
    this.userDetails = (this.userService.user)
    this.getUsers()
    // console.log(this.userDetails)
  }
  getUsers() {
    this.userService.getUsers(this.page).subscribe({
      next: (res: any) => {
        this.users = res.data
        const meta = res.meta
        this.size = meta.total
        this.pageSize = meta.per_page
        console.log('Users', res)
      },
      error: (err: any) => {
        console.log(err)
        // Swal.fire('Error', err.error.error, 'error')
      }
    })
  }
  onSearch() {
    setTimeout(() => {
      this.userService.getUsers(this.page, this.search).subscribe({
        next: (res: any) => {
          this.users = res.data
          const meta = res.meta
          this.size = meta.total
          this.pageSize = meta.per_page
          console.log('Users', res)
        },
        error: (err: any) => {
          console.log(err)
          // Swal.fire('Error', err.error.error, 'error')
        }
      })
    }, 3000);
  }
  viewBalance(id: any) {
    // alert('test')
    this.router.navigateByUrl(`/admin/waiter/waiter_details/${id}`)
  }
  capitalizeFirstLetter(sentence: string) {
    if (!sentence) return sentence; // Check if the sentence is empty or undefined
    return sentence.charAt(0).toUpperCase() + sentence.slice(1);
  }
  Filter(filter: any) {
    this.selectedRole = filter;
    this.filter = filter
    this.userService.getUsers(this.page, this.search, this.filter, this.status).subscribe({
      next: (res: any) => {
        this.users = res.data
        const meta = res.meta
        this.size = meta.total
        this.pageSize = meta.per_page
        console.log('Users', res)
      },
      error: (err: any) => {
        console.log(err)
        // Swal.fire('Error', err.error.error, 'error')
      }
    })

  }

  clearFilter() {
    this.page = 1
    this.search = null
    this.filter = null
    this.status = null
    this.getUsers()
  }
  statuusFilter(status: any) {
    this.selectedStatus = status;
    this.status = status
    this.userService.getUsers(this.page, this.search, this.filter, this.status).subscribe({
      next: (res: any) => {
        this.users = res.data
        const meta = res.meta
        this.size = meta.total
        this.pageSize = meta.per_page
        console.log('Users', res)
      },
      error: (err: any) => {
        console.log(err)
        // Swal.fire('Error', err.error.error, 'error')
      }
    })
  }
  refresh(event: any) {
    this.page = event
    this.getUsers()
  }
  createuser() {
    this.router.navigateByUrl('/admin/user/create_new_user')
  }
  edit(id: any) {
    // alert('test')
    this.router.navigateByUrl(`/admin/user/edit_user/${id}`)
  }


  updateStatus(id: any) {
    this.userService.updateStatus(id).subscribe({
      next: (res) => {
        Swal.fire('Success', 'Status updated successfully', 'success')
      },
      error: (err) => {
        Swal.fire('Failed', err.error.message, 'error')
      },
      complete: () => {
        this.getUsers()
      }
    })
  }
}

// email: "ayodejieluwande@gmail.com"
// first_name: "Ayo"
// id: 6
// last_name: "Elu"
// phone: "08089932753"
// roles: Array["admin"]
