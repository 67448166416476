import { Component, OnInit, inject, signal } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
import { InventoryService } from '../_service/inventory.service';
import Swal from 'sweetalert2';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-bulk-upload',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, NgbProgressbarModule],
  templateUrl: './bulk-upload.component.html',
  styleUrls: ['./bulk-upload.component.scss']
})
export class BulkUploadComponent implements OnInit {
  searchTerm: string = '';
  inventories: any[] = [];
  filteredInventories: any[] = [];
  isLoading: boolean = false;

  // Inject InventoryService
  private inventoryService = inject(InventoryService);

  ngOnInit(): void {
    this.listInventory();
  }

  /**
   * Fetches the list of inventories from the service.
   * Adds a `quantity` field to each inventory for bulk editing.
   */
  listInventory(): void {
    this.inventoryService.listInventories().subscribe({
      next: (res: any) => {
        this.inventories = res.data.map((item: any) => ({
          ...item,
          quantity: null // Initialize quantity field
        }));
        this.filteredInventories = [...this.inventories]; // Initialize filtered list
      },
      error: (err) => {
        Swal.fire('Error', 'Failed to fetch inventories. Please try again.', 'error');
        console.error(err);
      }
    });
  }

  /**
   * Filters inventories based on the search term.
   */
  filterInventories(): void {
    this.filteredInventories = this.inventories.filter((inventory: any) =>
      inventory.name.toLowerCase().includes(this.searchTerm.toLowerCase())
    );
  }

  /**
   * Prepares the payload and sends a bulk upload request.
   * Only inventories with a specified quantity are included in the payload.
   */
  bulkUpload(): void {
    const payload = this.filteredInventories
      .filter((item: any) => item.quantity > 0) // Include only items with valid quantities
      .map((item: any) => ({
        inventory_id: item.id,
        quantity: item.quantity
      }));

    if (payload.length === 0) {
      Swal.fire('Error', 'No items to upload. Please specify quantities.', 'error');
      return;
    }

    this.isLoading = true;

    this.inventoryService.bulkRestock(payload).subscribe({
      next: () => {
        Swal.fire('Success', 'All stocks uploaded successfully!', 'success');
        this.listInventory(); // Refresh inventory list after success
      },
      error: (err) => {
        Swal.fire('Error', err.error.message || 'Failed to upload stocks.', 'error');
        console.error(err);
      },
      complete: () => {
        this.isLoading = false;
      }
    });
  }
}
