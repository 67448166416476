<div class="mt-5">
  <ng-template #orderModal>
    <div class="modal-body" id="orderDetailsModal" tabindex="-1" aria-labelledby="orderDetailsLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="orderDetailsLabel">Order Details</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()"></button>
          </div>
          <div class="modal-body">
            <h6>Waiter Information</h6>
            <div class="d-flex align-items-center mb-3">
              <img [src]="selectedOrder?.waiter?.image || 'profile.png'" width="50" height="50" class="rounded-circle me-3" alt="">
              <div>
                <p class="mb-0"><strong>{{ selectedOrder?.waiter?.first_name }} {{ selectedOrder?.waiter?.last_name }}</strong></p>
                <p class="text-muted mb-0">{{ selectedOrder?.waiter?.phone }}</p>
                <p class="text-muted">{{ selectedOrder?.waiter?.email }}</p>
              </div>
            </div>
  
            <h6>Order Items</h6>
            <table class="table">
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Quantity</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of selectedOrder?.order_items">
                  <td>
                    <img [src]="item.image" width="40" height="40" class="rounded me-2" alt="">
                    {{ item.inventory }}
                  </td>
                  <td>{{ item.quantity }}</td>
                  <td>₦{{ item.amount }}</td>
                </tr>
              </tbody>
            </table>
  
            <!-- Total Amount -->
            <div class="d-flex justify-content-between mt-3">
              <h5>Total Amount:</h5>
              <h5 class="fw-bold">₦{{ getTotalOrderAmount() }}</h5>
            </div>
          </div>
          <div class="modal-footer">
            <!-- Replace the existing close button with this -->
            <button type="button" class="btn btn-danger" (click)="closeOrder(selectedOrder.uid)">Close Order</button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  
  
  <div class="row">
    <div class="col-md-4 mb-3">
      <!-- <h4 class="earning-title">Total sales</h4> -->
      <div class="balance-card py-3 pl-3">
        <p class="balance-text">
          Total Sales
        </p>
        <h3 class="balance-amount mt-auto">
          {{totalSales.total_sales || 0 | currency: '₦'}}
        </h3>
      </div>
    </div>
    <div class="col-md-4 mb-3">
      <div class="balance-card py-3 pl-3">
        <p class="balance-text">
          Waiter Earnings
        </p>
        <h3 class="balance-amount mt-auto">
          {{totalSales.total_waiter_earnings || 0 | currency: '₦'}}
        </h3>
      </div>
    </div>

  </div>
</div>

<div class="my-5">
  <div class="row g-5">
    <div class="col-md-6">
      <h4 class="earning-title">Inventory level</h4>
      
      <div class="card rounded-3 invcard p-3">
        
        <!-- Filter Buttons -->
        <div class="d-flex justify-content-end mb-2">
          <button class="btn btn-light me-2">
            Quantity <i class="bi bi-funnel"></i>
          </button>
          <button class="btn btn-light">
            Category <i class="bi bi-funnel"></i>
          </button>
        </div>
    
        <div class="table-responsive">
          <table class="table align-middle border rounded">
            <thead>
              <tr>
                <th class="ps-3">Inventory Item</th>
                <th class="text-end pe-4">Quantity</th>
              </tr>
            </thead>
            <tbody>
              @for(inv of inventories(); track inv.id) {
              <tr>
                <td class="d-flex align-items-center ps-3">
                  <img [src]="inv?.main_image || 'profile.png'" width="50" height="50" class="rounded me-3" alt="">
                  <span>{{ inv?.name }}</span>
                </td>
                <td class="text-end pe-4">
                  <span [ngClass]="{
                    'text-danger fw-bold': inv?.quantity <= 25 && inv?.quantity > 0,
                    'text-muted fw-bold': inv?.quantity == 0
                  }">
                    {{ inv?.quantity }}
                  </span>
                </td>
              </tr>
              }
              @empty {
              <tr class="text-center">
                <td colspan="2">
                  <div class="my-5 text-center">
                    <img src="cook.png" alt="">
                    <p class="mt-3">No inventory items available</p>
                    <button class="btn btn-primary" (click)="addInv()">Add Inventory</button>
                  </div>
                </td>
              </tr>
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
    
    <div class="col-md-6">
      <h4 class="earning-title">Pending Orders</h4>
      <div class="card rounded-3 invcard">
        <div class="table-responsive">
          <table class="table table-borderless align-middle">
            <tbody>
              @for (item of pendingOrders; track item.created_at) {
                <tr>
                  <td class="py-3 ps-3">Order from {{ item?.barcode_details.name }}</td>
                  <td class="py-3 text-end pe-3">
                    <span class="text-mute" [title]="item.created_at | date:'medium'">
                      {{ item.human_readable_date }}
                    </span>
                  </td>
                  <td class="text-end">
                    <button class="btn btn-sm btn-primary" (click)="openOrderDetails(item, orderModal)">
                      View Details
                    </button>
                  </td>
                </tr>
              }
              @empty {
                <tr>
                  <td colspan="4">
                    <div class="text-center my-5">
                      No data to display
                    </div>
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
    
  </div>

  <div class="row mt-1 g-5">
    <div class="col-md-6">
      <h4 class="earning-title">Active Waiters</h4>
      <div class="card rounded-3 invcard">
        <div class="table-responsive">
          <table class="table table-borderless align-middle">
            @for (item of activeWaiters; track item.created_at) {
              <tr>
                <td scope="row" class="ps-3" style="width: 60px !important;">
                  <img [src]="item?.image || 'profile.png'" width="50" height="50" class="rounded-circle " alt="">
                </td>
                <td class="py-2 ps-3">{{ item?.first_name + ' '+ item?.last_name}}</td>
                <td class="text-end pe-5"><i class="fa fa-solid fa-star"></i> 4.90</td>
              </tr>
              }
              @empty {
              <tr>
                <td colspan="4">
                  <div class="text-center my-5">
                    No data to display
                  </div>
                </td>
              </tr>
            }
          </table>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <h4 class="earning-title">Activity Logs </h4>
      <div class="card rounded-3 invcard">
        <div class="table-responsive">
          <table class="table table-borderless align-middle">
            <tbody>
              @for (item of activityLogs; track item.created_at) {
              <tr>
                <td class="py-3 ps-3">{{ item?.log }}</td>
                <td class="py-3 text-end pe-3"><span class="text-mute" [title]="item.created_at | date:'medium'">
                  {{ item.human_readable_date }}
                </span></td>
              </tr>
              }
              @empty {
              <tr>
                <td colspan="4">
                  <div class="text-center my-5">
                    No data to display
                  </div>
                </td>
              </tr>
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <div class="my-4">
  <div class="balance-card py-4 pl-5">
      <div class="container">
        <div class="row mb-3">
          <div class="colmd-6">
            <p class="balance-text">Sales {{getCurrentYear()}}</p>
            <h3 class="balance-amount">{{salesChart && salesChart.total | currency : '₦'}}</h3>
          </div>
          <div class="colmd-6"></div>
        </div>
        <div id="chart1" echarts [options]="options" theme="macarons" class="demo-chart"></div>
      </div>
  </div>

</div> -->